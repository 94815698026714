import {Box, Heading,  Image, Text, Center, 
    Card, CardHeader, CardBody, CardFooter, 
    Button, useBreakpointValue, 
    useDisclosure,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
     Tag,
    Wrap,
    WrapItem,
    Tooltip } from '@chakra-ui/react';
import { ChevronDownIcon} from '@chakra-ui/icons'
import React from 'react';
//import { BiLike, BiChat } from 'react-icons/bi';
import Slider from 'react-slick';
import {motion} from 'framer-motion';
import {useState} from 'react';

import './styles/project.css';

import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";


// create a list of projects and store it in an array
// add description and media - picture / video

// NOT FINISHED 
// ~ Add the project video demos
// ~ Add the project description

// ~ Implement link change with each project change

const MotionBox = motion(Box);

// Settings for the slider - React Slick`
const settings = {
    className: "center",
    centerMode: true,
    // dots: true,
    arrows: true,
    // fade: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed:20000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    centerPadding: '10px'
};

/**
 * Renders a project card component.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.title - The title of the project.
 * @param {string} props.desc - The description of the project.
 * @param {string} props.media - The media (image or video) associated with the project.
 * @param {string} props.link_to_demo - The link to the demo of the project.
 * @param {string} props.link_to_repo - The link to the repository of the project.
 * @param {string} props.tags - The tags associated with the project.
 * @return {JSX.Element} The rendered project card component.
 */

function ProjectCard({title, desc, media, link_to_demo, link_to_repo, tags, ...rest}){
    const { isOpen, onToggle } = useDisclosure()

    const [isFlipped, setIsFlipped] = useState(false);
    const [tooltip, setTooltip] = useState({ display: 'none', x: 0, y: 0 });
    const [text, showText] = useState(false);

    const flipCard = () => setIsFlipped(!isFlipped);

    const tagsArray = tags.split(', '); 
    return(
        
        <Center>
             <div className="flip-card" style={{perspective: '500px'}} onClick={flipCard}>
                <motion.div
                    className="flip-card-inner"
                    width="100%"
                    height="100%"
                    initial={false}
                    animate={{ rotateY: isFlipped ? 180 : 0 }} // Rotates the card
                    transition={{ duration: 0.6 }}
                    style={{ position: 'relative', transformStyle: 'preserve-3d', transition: 'transform 0.6s'}} // Needed for the 3D effect
                >
                    {/* Front of the card - showing video */}
                    <MotionBox
                        className="flip-card-front"
                        // style={{backfaceVisibility: 'hidden',
                        //     WebkitBackfaceVisibility: 'hidden', // Safari support
                        //  }} // Hides the backside during rotation
                        position="absolute"
                        // top={0}
                        // left={0}
                        width="100%"
                        height="100%">
                        
                        <Box display='flex'
                            justifyContent='center'>
                                <Tooltip label="Click to view more information " aria-label="A tooltip" color={'black'} backgroundColor={'#501a1f'} placement='bottom' >
                                <video className="background-video" autoPlay loop muted playsInline width="" height="60vh" >
                                <source src={media} type="video/mp4" />
                                Your browser does not support the video tag.</video>
                                
                            </Tooltip>
                                
                        </Box>
                        
                            
                    </MotionBox>

                    {/* Back of the card - showing card information */}
                    <MotionBox
                        className="flip-card-back"
                        style={{ backgroundColor: '#410b0b', transform: 'rotateY(180deg)', width:"auto", height:'100%', borderRadius:'100px' }} // Hides the front side when flipped
                        position="absolute"
                        top={0}
                        left={0}

                    >
                        <Tooltip label="Click to view video" aria-label="A tooltip" color={'black'} backgroundColor={'#501a1f'} placement='bottom' >
                            <Card w={'100%'} h = {'100%'} borderRadius={'100px'} boxShadow={'0px 0px 0px 0px grey'} >
                                <CardHeader>
                                    <Center>
                                        <Heading fontFamily="Pathway Gothic One" size={{ base: 'lg', md: 'md', lg: 'lg' }} colour ={"#0c0a22"}>
                                            {title}
                                        </Heading>
                                    </Center>
                                </CardHeader>
                                <Center>
                                    <CardBody >
                                        <Text textAlign="center" fontFamily="Pathway Gothic One" fontSize={{ base: '1rem', md: '1.1rem', lg: '1.4rem', xl: '1.2rem' }}>
                                            {desc}
                                        </Text>
                                    </CardBody>
                                </Center>
                                <Center>
                                    <CardFooter w={'100%'}  display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}> 
                                        <Center>
                                        <Wrap spacing={3} >
                                            {tagsArray.map((tag, index) => (
                                                <WrapItem alignItems={'center'}>
                                                    <Center>
                                                        <Tag size={{base: 'sm', md: 'lg'}} key ={index} borderRadius={'full'} variant = 'solid' backgroundColor ="#0b4141" _hover={{backgroundColor:'#0c0a22'}}>
                                                        # {tag}
                                                        </Tag>
                                                    </Center>
                                                    
                                                </WrapItem>
                                                
                                            ))}
                                        </Wrap>
                                        </Center>

                                        {/* <Center marginTop={'2em'}>
                                                        <Menu isLazy>
                                                            <MenuButton fontFamily={"Pathway Gothic One"} backgroundColor={'#501a1f'} color={'white'} _hover={{backgroundColor:'#0c0a22' }} as ={Button} rightIcon={<ChevronDownIcon /> }>
                                                                Take a better look
                                                            </MenuButton>
                                                            <MenuList>
                                                                <MenuItem fontFamily={"Pathway Gothic One"} as = 'a' href={link_to_repo}>View Source Code...</MenuItem>
                                                                <MenuItem fontFamily={"Pathway Gothic One"} as = 'a' href={link_to_demo}>View Demo...</MenuItem>
                                                            </MenuList>
                                                        </Menu>
                                                
                                                    </Center> */}

                                    </CardFooter>
                                </Center>
                               
                            </Card>
                        </Tooltip>
                    </MotionBox>
                </motion.div>
            </div>
        </Center>       
    )
}



/**
 * Renders the Projects component which displays a list of project cards.
 *
 * @return {JSX.Element} The rendered Projects component.
 */
function Projects(){

    // useState to store the current state of the slider
    const [slider, setSlider] = React.useState(null);

    // These are the breakpoints which changes the position of the buttons as the screen size changes 
    const top = useBreakpointValue({ base: '90%', md: '50%'});
    const side = useBreakpointValue({ base: '30%', md: '40px'});

    return(
        <>
        
        <Box className="projects-container" 
            h={{ base: "auto", md: "auto", lg: "auto", xl: "auto" }}
            w={"auto"} 
            display={"flex"} 
            borderRadius={{ base: "160px", md: "100px", lg: "100px", xl: '100px'}}
            margin={"50px"}
            flexDirection={{ base: "column", md: "column", lg: "row", xl: "row" }}
            alignItems={"center"} 
            paddingTop={{ base: "10px", md: "10px", lg: "20px", xl: '20px'}}>
            <Box width={{ base: "100%", md: "50%" }} 
            h={{ base: "auto", md: "auto", lg: "auto" }}>
                <Heading color={"#410b0b"}
                    textAlign={"center"} 
                    fontFamily={"Pacifico, cursive"}
                    size={{ base: "2xl", md: "4xl" }}  
                    marginTop={{ base: "6vh", md: "10vh", lg: "0vh", xl: "0vh" }}
                    marginLeft={{ base: "0vh", md: "0vh", lg: "5vh", xl: "10vh" }}
                    textShadow={{ base: "1px 1px #000000", md: "2px 2px #00000", lg: "2px 2px #000000" }}
                    // marginTop={{ base: "0vh", md: "10vh", lg: "20vh", xl: "30vh" }}
                    _after={{content: "''", 
                    display: "block",  
                    width: "100px", 
                    
                    borderBottom: "3px solid #000000", 
                    margin: "auto", 
                    marginTop: { base: "15px", md: "20px", lg: "40px" }}}
                    _hover={{
                        // color: "#501a1f",
                        // textShadow:"1px 1px #000",
                        _after:{
                            width: '35%',
                            borderBottom: "3px solid #410b0b",
                        }
                    }}
            >Projects</Heading>
            </Box>
            

        
                <Box 
                    width={"auto"}
                    h={"auto"}
                    maxW={"100vw"}
                    overflow={"hidden"} 
                    marginTop= {{ base: "25px", md: "20px", lg: "40px" }}
                    //paddingTop={{ base: "70px", md: "20px", lg: "40px" }}
                    
                    alignItems={"center"}
                    className="slider-container">

                    {/* add the css style sheet for the React Slick component - Slider/Carousel */}
                    <link
                        rel="stylesheet"
                        type="text/css"
                        charSet="UTF-8"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                    />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                    />

                    {/* add Slider here */}
                    <Slider {...settings} ref={(slider) => setSlider(slider)}>
                        {/* add the project cards here  */}
                        {projects.map((card, index) => (
                            <>
                            <ProjectCard key={index} title={card.title} desc={card.text} media={card.media} tags={card.tags} margin= "30px"></ProjectCard>
                            <Center margin={'2em'}>
                                                <Menu isLazy >
                                                    <MenuButton size={{ base: "md", md: "md", lg: "lg" }} fontFamily={"Pathway Gothic One"} backgroundColor={'#0c0a22'} color={'white'} _hover={{backgroundColor:'#0c0a22' }} as ={Button} rightIcon={<ChevronDownIcon /> }>
                                                        Take a better look
                                                    </MenuButton>
                                                    <MenuList backgroundColor={"#f2e2d2"}>
                                                        <MenuItem backgroundColor={"#f2e2d2"} fontFamily={"Pathway Gothic One"} as = 'a' href={card.link_to_repo}>View Source Code...</MenuItem>
                                                        <MenuItem backgroundColor={"#f2e2d2"} fontFamily={"Pathway Gothic One"} as = 'a' href={card.link_to_demo}>View Demo...</MenuItem>
                                                    </MenuList>
                                                </Menu>
                                        
                                            </Center>
                             </>
                        ))}
                    </Slider>
                    

                </Box>           

        </Box>
        </>
    )
}


const projects = [
    {
        title: 'Dream Closet',
        text: 
            "Enter Dream Closet, a solution designed to streamline your wardrobe management experience. By virtualizing your closet, Dream Closet helps you to effortlessly create, view, and save outfits using your existing collection of clothing items. rs the ability to create a virtual copy of their entire wardrobe. Dream Closet allows one to seamlessly blend styles, experiment with new looks, and effortlessly curate outfits for any occasion at any time.",
        // add video demo here
        media:
            `/videos/placeholder_video.mp4`,

        link_to_demo: `https://main--dreamcloset.netlify.app/`,
        link_to_repo: `https://github.com/dominico25/seng401-project`,
        tags: 'JavaScript, HTML, CSS, React, AWS, Cloudinary, ChakraUI'
    },

    {
        title: 'NSBE UCalgary Website',
        text: 
            "The NSBE UCalgary Website is a comprehensive platform designed for the National Society of Black Engineers at the University of Calgary. This website serves as a central hub for information, events, and resources for members and visitors. It features a modern design, user-friendly navigation, and integrates various functionalities to enhance user engagement and community building",
        // add video demo here
        media:
            `/videos/placeholder_video.mp4`,
        // add links here
        link_to_demo: `https://www.nsbeucalgary.ca/`,
        link_to_repo: `https://github.com/fisayoadabs/nsbeweb`,
        tags: 'JavaScript, HTML, CSS, React, AWS, S3, TailwindCSS'
    },

    {
        title: 'Personal Website - Upcoming',
        text:
        // add the description here 
            "My personal website highlights my journey in tech and advocacy for diversity in STEM. It features a dynamic intro showcasing my passions, along with sections on my education, experience, and leadership roles. The \"About Me\" section reflects my values, and the portfolio showcases key projects. It’s designed to be clean and approachable, giving a clear snapshot of my skills, accomplishments, and leadership in various initiatives.",
        
        // add video demo here
        media:
            `/videos/project1_.mp4`,
        // add links here
        link_to_demo: `https://www.eshiakalumhe.com/`,
        link_to_repo: `https://github.com/eshi-A/project1-personal-website`,
        tags: 'JavaScript, HTML, CSS, React, Express, Node.js'
    },
    
    {
        title: 'CareCircle - Upcoming',
        text: 
            "CareCircle is an innovative mobile application aimed at providing a supportive community for caregivers. This app will offer features such as task management, resource sharing, and communication tools to help caregivers manage their responsibilities more effectively. Built with Java and Kotlin, CareCircle leverages Android Studio and Firebase for a robust and scalable solution. ",
        
        // add video demo here
        media:
            `/videos/placeholder_video.mp4`,
        // add links here
        link_to_demo: `https://www.dreamcloset.com/`,
        link_to_repo: `https://www.dreamcloset.com/`,
        tags: 'Java, Kotlin, Android Studio, Firebase'
    },

    // {
    //     title: 'Untitled - Upcoming',
    //     text:
    //     // add the description here 
    //         "Upcoming Project, stay tuned!",
        
    //     // add video demo here
    //     media:
    //         `/videos/placeholder_video.mp4`,
    //     // add links here
    //     link_to_demo: `https://www.dreamcloset.com/`,
    //     link_to_repo: `https://www.dreamcloset.com/`,
    //     tags: ''
    // },

    // {
    //     title: 'Untitled - Upcoming',
    //     text:
    //     // add the description here 
    //         "Upcoming Project, stay tuned!",
        
    //     // add video demo here
    //     media:
    //         `/videos/placeholder_video.mp4`,
    //     // add links here
    //     link_to_demo: `https://www.dreamcloset.com/`,
    //     link_to_repo: `https://www.dreamcloset.com/`,
    //     tags: ''
    // },

    // {
    //     title: 'Untitled - Upcoming',
    //     text:
    //     // add the description here 
    //         "Upcoming Project, stay tuned!",
        
    //     // add video demo here
    //     media:
    //         `/videos/placeholder_video.mp4`,
    //     // add links here
    //     link_to_demo: `https://www.dreamcloset.com/`,
    //     link_to_repo: `https://www.dreamcloset.com/`,
    //     tags: ''
    // }

]

export default Projects
