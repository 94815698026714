import React, { useEffect } from 'react';
import { Heading } from '@chakra-ui/react';
 
import './styles/education.css';

import img1 from './images/img_high.jpg';
import img2 from './images/img_gap.jpeg';
import img3 from './images/img_uni.jpeg';

/**
 * Renders a timeline component with a list of education items. Updates the active item and background image based on the scroll position.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.items - An array of education items.
 * @return {JSX.Element} The rendered timeline component.
 */
const Timeline = ({items}) => {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [backgroundImg, setBackgroundImg] = React.useState(items[0].img);

    useEffect(() => {
        const scrolltoTimeline = () => {
            const position = window.scrollY;
            console.log('Scroll Position:', position);

            items.forEach((item, index)=> {
                const element = document.getElementById(item.id);
                const rect = element.getBoundingClientRect();
                const min = rect.top + window.scrollY;
                const max = min + element.offsetHeight;
                
                console.log(`Item ${index}: min=${min}, max=${max}`);

                if(index === items.length - 1 && position > min + element.offsetHeight / 2) {
                    setActiveIndex(items.length - 1);
                    setBackgroundImg(items[items.length - 1].imgSrc);
                } else if (position <= max - 40 && position >= min) {
                    setBackgroundImg(item.imgSrc);
                    setActiveIndex(index);
                }
            });
                
            };

        window.addEventListener('scroll', scrolltoTimeline);
        return () => window.removeEventListener('scroll', scrolltoTimeline);
    }, [items]);



    return(
        <>
            <div class = 'heading' style={{textAlign: 'center', padding: '2rem 0', width: '100%'}}>
                <Heading color={"white"}
                fontFamily={"Pacifico, cursive"}
                textAlign={"center"} 
                size={"4xl"} 
                textShadow={{ base: "1px 1px #b5b6b7", md: "2px 2px #b5b6b7", lg: "2px 2px #b5b6b7" }}
                h={"100%"}
                margin={"5vh"}
                _after={{content: "''", 
                display: "block",  
                width: "100px", 
                
                borderBottom: "3px solid #b5b6b7", 
                margin: "auto", 
                marginTop: "30px", }}
                _hover={{
                    // color: "#501a1f",
                    // textShadow:"1px 1px #000",
                    _after:{
                        width: '20%',
                        borderBottom: "3px solid #501a1f"
                    }
                }}>Education</Heading>
            </div>
           <div className='timeline_container' 
        //    style={{backgroundImage: `url(${backgroundImg})`}}
        >

            <div className='timeline'>
                {/* <Timeline /> */}

                {items.map((item, index) => (
                <div 
                key={index}
                id={item.id}
                className={`timeline_item ${activeIndex === index ? 'timeline_item--active' : ''}`}
                data-text={item.date}>  
                    <div className='timeline_content'>
                        
                        <img className='timeline_img' src={item.imgSrc} alt={`Timeline Image ${index}`}></img>
                        <h2 className='timeline_title'>{item.title}</h2>
                        {/* <h3 className='timeline_date'>{item.date}</h3> */}

                        <ul className='timeline_description'>
                                    {item.activities.map((activity, i) => (
                                        <li key={i}>{activity}</li>
                                    ))}
                        </ul>
                    </div>

                </div>
                ))}
            </div>
            </div>
        </>
    )
}

const timelineItems = [
    {
        id: 'education-1',
        imgSrc: img1,
        title: 'Chrisland High School',
        date: '2014-2020',
        activities: ['Model UN', 'Volleyball', 'Robotics', 'Choir'],
    },
    {
        id: 'education-2',
        imgSrc: img2,
        title: 'Aptech University',
        date: '2020-2021',
        activities: ['App Development', 'Software Development', 'Core & Advanced Java'],
    },

    {
        id: 'education-3',
        imgSrc: img3,
        title: 'University of Calgary',
        major: 'Software Engineering',
        minor: 'Mechtronics Engineering',
        date: '2022-2026 (Expected)',
        activities: ['National Society of Black Engineers (NSBE)', 'Robogals', 'Electrical and Software Engineering Students\' Society (ZOO)', 'Schulich Ignite'],
    }
]

/**
 * Renders the Education component with a Timeline of education items.
 *
 * @return {JSX.Element} The rendered Education component.
 */
function Education(){
    return(
        <Timeline items={timelineItems}/>
    )
}
export default Education;