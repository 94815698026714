import {ChakraProvider, Box, Spinner, Text} from '@chakra-ui/react';
import { useRef, useEffect, useState  } from 'react';
import React from 'react';
import Header from './Header';
import About from './About';
import Projects from './Projects';
import Skills from './Skills';
import Education from './Education';
import Experience from './Experience';
import ContactForm from './ContactForm';
import TOPOLOGY from 'vanta/dist/vanta.topology.min';
import * as THREE from 'three'; // Vanta.js requires THREE
import p5 from 'p5'; // Vanta.js requires p5


import logo from './logo.svg';
import './App.css';


/**
 * A component that renders a background with a Vanta.js topology animation.
 * The setLoading prop is a callback function that is called when the animation is ready.
 * The animation is rendered in a full-screen div with a negative z-index,
 * so it will appear behind all other elements.
 */
const VantaBackground = ({ setLoading}) => {
  const vantaRef = useRef(null); // To reference the background div
  const [vantaEffect, setVantaEffect] = useState(null);

  useEffect(() => {
    

    if (!vantaEffect && vantaRef.current) {
      console.log('Initializing Vanta.js effect');
      console.log('vantaRef.current:', vantaRef.current);
      try {
        console.log('Checking if THREE and p5 are defined:', THREE, p5); 
        // Check if these are loaded
        console.log('Checking if Vanta.js is loaded:', vantaRef.current);
        const effect = TOPOLOGY({
          el: vantaRef.current,
          THREE: THREE,
          p5: p5,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.00,
          minWidth: 200.00,
          scale: 1.00,
          scaleMobile: 1.00,
          color: 0x020e23,
          backgroundColor: 0x000000,
          
          onload: () => {
            console.log('Vanta.js effect loaded');
            setLoading(false);
          }
        });
        setVantaEffect(effect);
      } catch (error) {
        console.error('Error initializing Vanta effect:', error);
      }
    }

    return () => {
      if (vantaEffect) {
        vantaEffect.destroy();
      }
    };
  }, [vantaEffect, setLoading]);


  return (
    <div ref={vantaRef} style={{ width: '100vw', height: '100vh', position: 'fixed', top: '0', left: '0', zIndex: '-10'}}>
      {/* This div will be the background */}
    </div>
  );
};

/**
 * The main App component.
 *
 * @return {JSX.Element} The rendered App component.
 */
function App() {

  const [loading, setLoading] = useState(false);
      
  // References to the sections of the page.
  const about = useRef(null);
  const project = useRef(null);
  const skills = useRef(null);
  const education = useRef(null);
  const experience = useRef(null);
  const contact = useRef(null);


  /**
   * Scrolls the window to the specified section smoothly.
   *
   * @param {object} elementRef - A reference to the DOM element to scroll to.
   * @return {void} No return value.
   */
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth"
    })
  }

  return (
    <ChakraProvider >
      <VantaBackground setLoading={setLoading} />
      {loading ? (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          display="flex"
          alignItems="center"
          flexDirection="column"
          fontFamily="Pacifico, cursive"
          fontSize={"5xl"}
          color={"#410b0b"}
          fontWeight="bold"
          textAlign={"center"}
          textShadow={{ base: "1px 1px 2px #000000", md: "2px 2px 4px #000000", lg: "2px 2px  4px #000000" }}
          justifyContent="center"
          backgroundColor="#000000"
          zIndex="9999"
        >
          
          <Text>Welcome to EshI <span style={{color: "#ffffff"}}>A</span></Text>

          <Spinner size="xl" color="white" margin={"30px"} />
        </Box>
      ) : (
        <>
          
          <Box  w = {"100%"} h = {"100vh"}>      
        {/* Header component */}
        <Header scrollToSection={scrollToSection}
              about={about} 
              project={project} 
              skills={skills} 
              education={education} 
              experience={experience} 
              contact={contact}  />
        {/* About component */}
        <div ref={about} className="about">
          <About contactRef={contact}/>
        </div>
        {/* Projects component */}
        <div ref={project} className="projects">
          <Projects/>
        </div>
        {/* Skills component */}
        <div ref={skills} className="skills">
          <Skills/>
        </div>
        {/* Education component */}
        <div ref={education} className="education">
          <Education/>
        </div>
        {/* Experience component */}
        <div ref={experience} className="experience">
          <Experience/>
        </div>
        {/* Contact component */}
        <div ref={contact} className="contact">
          <ContactForm/>
        </div>
      </Box>
        </>
        
      )}
      
    </ChakraProvider>
  );
}

export default App;
