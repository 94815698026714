import {Box, Text,
    Heading, 
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText, 
    Input,
    Button, 
    CloseButton,
    Circle, 
    Alert,
    AlertDescription,
    AlertIcon, 
    AlertTitle} from '@chakra-ui/react';

import { BeatLoader } from 'react-spinners';
import { SocialIcon} from 'react-social-icons';
import { motion } from 'framer-motion';

import './styles/contact.css';

import { useState } from 'react';

import axios from 'axios';

// const colors = ['black', '#0077b5', '#5865f2', "#1db954"]

// const socialIcons = [
//     {url: 'mailto:eshilama.akalumhe@ucalgary.ca', color: '#282c34'}
// ]

// add a function that checks the form data now 
// if name is missing - return warning 
// if email is misssing - return erorr
// if message is missing return error message
function ContactForm (){
    const [isSubmitSuccessful, setSuccessfulSubmit] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const [isError, setIsError] = useState(false);

    const [errorMessage,setErrorMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        validateForm(event);


        if(isError === false){
            try{
                // send email
            const response = await axios.post('/api/contact', formData);
            if (response.status === 200) {
                setSuccessfulSubmit(true);
                console.log('Form Data', formData);
                console.log('Form Submitted Successfully');
            }

            else {
                setSuccessfulSubmit(false);
                console.log('Form Errors', formErrors);
                console.log('Form Not Submitted.');
            }
            }
            catch(error){
                setSuccessfulSubmit(false);
                console.log('Error Submitting Form', error);
                console.log('Form Not Submitted. Server Error.');
            }
        }
        else{
            setSuccessfulSubmit(false);
            console.log('Form Errors', formErrors);
            console.log('Form Not Submitted. Missing vital Information');
        }
      
    }

    /**
     * Validates form data based on user input.
     *
     * @param {object} event - The event object triggered by user input.
     * @return {void}
     */
    const validateForm = (event) => {
         // Validate input from users
        const { name, value } = event.target;

        // Update form data
        setFormData({
            ...formData,
            [name]: value
        })

        // Validate form data
        if (name === 'name' && value.trim() === '') {
            setFormErrors({
                ...formErrors,
                name: `${name} is required.`
            });
            setIsError(true);
            setErrorMessage('Name is required');
        }

        else if(name === 'email' && ((value.trim() === '') || (value.includes('@') === false))){
            setFormErrors({
                ...formErrors,
                email: `${name} is required.`
            });
            setIsError(true);
            setErrorMessage('Email is required');
        }
        else if(name === 'subject' && (value.length < 10 || value === '')){
            setFormErrors({
                ...formErrors,
                subject: `${name} is required. `
            });
            setIsError(true);
            setErrorMessage('Subject is required');
        }
        else if(name === 'message' && (value.length < 10 || value === '')){
            setFormErrors({
                ...formErrors,
                message: `${name} is required. `
            });
            setIsError(true);
            setErrorMessage('Message is required');
        }
        else{
            setFormErrors({
                ...formErrors,
                name: '',
                email: '',
                subject: '',
                message: ''
            });
            setIsError(false);
        }
    }

    const handleInputChange = (event) => {
        validateForm(event);
    }

    return(
        <>
        <Box  h={"150vh"} 
                        minH={"100vh"}
                        display={"flex"} 
                        flexDirection={"column"}
                        alignItems={"center"} >
            <Heading color={"white"} 
                    textAlign={"center"} 
                    size={{ base: "2xl", md: "4xl" }} 
                    fontFamily={"Pacifico, cursive"}
                    textShadow={{ base: "1px 1px #b5b6b7", md: "2px 2px #b5b6b7", lg: "2px 2px #b5b6b7" }}
                    _after={{content: "''", 
                            display: "block",  
                            width: "100px", 
                            borderBottom: "3px solid #b5b6b7", 
                            margin: "auto", 
                            marginTop: "30px", }}
                            _hover={{
                                // color: "#501a1f",
                                // textShadow:"1px 1px #000",
                                _after:{
                                    width: 'auto',
                                    borderBottom: "3px solid #501a1f"
                                }
                            }}>Contact <span class = "coloured-text">Me</span></Heading>
            
            <Box border={"1px solid #b5b6b7"} 
                 padding={{ base: 8, md: 15, lg: 30 }} 
                bg={"white"}
                borderRadius={"10px"} 
                margin={"auto"} 
                width={{ base: "90%", md: "50%" }}  
                height={{ base: "auto", md: "60%" }}
                display={"flex"}
                flexDirection={"column"}
                marginBottom={"40px"}
                justifyContent={"center"}
                alignItems={"center"}
                boxShadow={"1px 1px 1px black"}
                as ={"form"}
                onSubmit={handleSubmit}
                // action='/send-email'
                // method="POST"
                >
                <FormControl h={"15vh"} 
                    marginTop={"10px"} 
                    isInvalid={!!formErrors.name}

                // Form: Name
                // isInvalid = {isError} 
                >
                    <FormLabel fontSize={{ base: "md", md: "lg", lg: "xl" }} 
                                fontFamily={"Pathway Gothic One, monospace"} 
                                // textAlign={"right"} 
                                // textTransform={"uppercase"}
                                >Name</FormLabel>
                    <Input type="text" 
                        name="name"
                        size={{ base: "md", md: "lg", lg: "lg" }}  
                        variant={"flushed"} 
                        focusBorderColor="#501a1f"
                        onChange={handleInputChange}
                        // placeholder='Enter your name' 
                        // _placeholder={{opacity: 0.5, color: "#282c34"}}
                    />

                    {formErrors.name && <FormErrorMessage>{formErrors.name}</FormErrorMessage>}
                    {/* {!isError ? (
                        <FormHelperText>
                            Enter the your name here
                        </FormHelperText>
                    ):(<FormErrorMessage focusBorderColor="#282c34" color={"#282c34"} textAlign={"right"}>Name is Required</FormErrorMessage>)}
                     */}
                </FormControl>

                <FormControl  h={"15vh"} marginTop={"10px"} 
                // Form: Email
                isInvalid = {!!formErrors.email}
                >
                    <FormLabel fontSize={"lg"} 
                                fontFamily={"Pathway Gothic One, monospace"} 
                                // textAlign={"right"} 
                                // textTransform={"uppercase"}
                                >Email Address</FormLabel>
                    <Input type="email"
                        name="email" 
                        size={{ base: "md", md: "lg", lg: "lg" }}   
                        variant={"flushed"} 
                        focusBorderColor="#501a1f" 
                        onChange={handleInputChange}
                        // placeholder='Enter your email'
                        // _placeholder={{opacity: 0.5}}
                        />
                    <FormHelperText textAlign={"right"} fontFamily={"Pathway Gothic One, monospace"}>I'll never share your email.</FormHelperText>
                </FormControl>

                <FormControl  h={"15vh"} marginTop={"10px"} isInvalid={!!formErrors.subject}
                // Form: Subject 
                // isInvalid = {isError}
                >
                    <FormLabel fontSize={{ base: "md", md: "lg" }}
                                fontFamily={"Pathway Gothic One, monospace"} 
                                // textAlign={"right"} 
                                // textTransform={"uppercase"}
                                >Subject</FormLabel>
                    <Input type="text"
                        name="subject" 
                        size={{ base: "md", md: "lg", lg: "lg" }}
                        variant={"flushed"} 
                        focusBorderColor="#501a1f" 
                        onChange={handleInputChange}
                        // placeholder='Enter your email'
                        // _placeholder={{opacity: 0.5}}
                        />
                </FormControl>

                <FormControl h = {"20vh"} isInvalid={!!formErrors.message}
                // Form: Message
                >
                    <FormLabel fontSize={"lg"} 
                                fontFamily={"Pathway Gothic One, monospace"} 
                                // textAlign={"right"} 
                                // textTransform={"uppercase"}
                                >Message</FormLabel>
                    <Input type="text" 
                            name="message"
                            size={{ base: "md", md: "lg", lg: "lg" }}
                            variant={"flushed"} 
                            focusBorderColor="#501a1f"
                            onChange={handleInputChange}
                            // placeholder='Enter your message' 
                            // _placeholder={{opacity: 0.5}}
                            />
                </FormControl>

                <Button color = {"#282c34"}
                        size={"lg"}
                        fontFamily={"Pathway Gothic One, monospace"}
                        // colorScheme= {"#282c34"}
                        spinner={<BeatLoader size={8} color='white'/>}
                        type='submit'
                        _hover={{bgColor: "#501a1f", color: "white"}}
                        >Submit
                </Button>
            </Box>

            {isSubmitSuccessful && (
                    <Alert status="success" variant="subtle" 
                    flexDirection="column" alignItems="center" 
                    justifyContent="center" textAlign="center" 
                    height="20vh"
                    margin={"auto"}
                    width={"50%"}>
                        <AlertIcon boxSize="40px" mr={0} />
                        <AlertTitle mt={4} mb={1} fontSize="lg">Form Submitted!</AlertTitle>
                        <AlertDescription maxWidth="sm">Thank you for reaching out. We will get back to you soon.</AlertDescription>
                        <CloseButton position="absolute" right="8px" top="8px" onClick={() => setSuccessfulSubmit(false)} />
                    </Alert>
             )}

             {!isSubmitSuccessful && isError && (
                    <Alert status="error" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="20vh">
                        <AlertIcon boxSize="40px" mr={0} />
                        <AlertTitle mt={4} mb={1} fontSize="lg">Form Not Submitted!</AlertTitle>
                        <AlertDescription maxWidth="sm">{errorMessage}</AlertDescription>
                        <CloseButton position="absolute" right="8px" top="8px" onClick={() => setIsError(false)} />
                    </Alert>
             )}

            


            <Box h={"auto"} bg ={"#b5b6b7"} w={"100%"} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} >
                <Box display = {"flex"} flexDirection={"row"} alignContent={"center"} justifyContent={"space-between"} marginBottom={"20px"}>
                    <Box  h={"auto"} margin={"20px 60px"} display={"flex"} flexDirection={"column"}  justifyContent={"space-evenly"}>

                        <motion.div
                                initial={{ x: '-100%', opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 2, delay: 0.5 }}>
                        <Heading margin={"10px 10px"} size={{ base: "md", md: "lg", lg: "2xl" }}>Eshilama Akalumhe</Heading>
                        </motion.div>

                        <motion.div
                                initial={{ x: '-100%', opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 2, delay: 1.0 }}>
                        <Heading margin={"5px 10px"} size={{ base: "sm", md: "md", lg: "xl" }} color={"#501a1f"}>Software Engineer</Heading>
                        </motion.div>

                        <motion.div 
                                initial={{ x: '-100%', opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 2, delay: 1.5 }}>
                        <Text margin={"5px 10px"} w={{ base: "80%", md: "80%" }} fontSize={{ base: "xs", md: "sm", lg: "md" }}>A driven student with a passion for software development and engineering. 
                            With a robust skill set in programming languages and a commitment to diversity in STEM, 
                            I am purposeful on making a meaningful impact. Contact me to explore collaboration opportunities today.</Text>
                        </motion.div>
                    </Box>
                    <Box w={{ base: "70%", md: "40%" }} >
                        <Heading margin={"20px 10px"} size={{ base: "md", md: "lg", lg: "2xl" }} textAlign={"center"}  >Social</Heading>
                        <Box display={"flex"} flexWrap={"wrap"}
                        justifyContent={"center"}
                        alignItems={"center"}>
                            <motion.div
                                    initial={{ x: '-100%', opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 2, delay: 0.3 }}
                                >
                            <Circle border = "3px solid #282c34" margin = "10px"
                            size={{ base: "50px", md: "60px", lg: "70px" }} >
                                <SocialIcon bgColor = "white" 
                                            fgColor= " #D14836" 
                                            style ={{height: "100%", width: "100%",
                                                    position: "relative",
                                                    zIndex: "1"}}
                                            url = "mailto:eshilama.akalumhe@ucalgary.ca"></SocialIcon>
                            </Circle>
                            </motion.div>

                            <motion.div
                                    initial={{ x: '-100%', opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 2, delay: 0.6}}
                                >
                            <Circle border = "3px solid #282c34" margin = "10px" 
                            size={{ base: "50px", md: "60px", lg: "70px" }}>
                                <SocialIcon bgColor = "white" 
                                            fgColor= "#181717" 
                                            style ={{height: "100%", width: "100%", 
                                                position: "relative", zIndex: "1"}}
                                            url = "https://github.com/eshi-A" ></SocialIcon>
                            </Circle>
                            </motion.div>
                                                
                            <motion.div
                                    initial={{ x: '-100%', opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 2, delay: 0.9 }}
                                >
                            <Circle border = "3px solid #282c34" margin = "10px"
                            size={{ base: "50px", md: "60px", lg: "70px" }}>
                                <SocialIcon bgColor = "white" 
                                            fgColor= "#0077B5" 
                                            style ={{height: "100%", width: "100%", 
                                                position: "relative", zIndex: "1"}}
                                            url = "https://www.linkedin.com/in/eshilama-akalumhe-855651214/"></SocialIcon>
                            </Circle>
                            </motion.div>

                            <motion.div
                                    initial={{ x: '-100%', opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 2, delay: 1.2 }}
                                >
                            <Circle border = "3px solid #282c34" margin = "10px"
                            size={{ base: "50px", md: "60px", lg: "70px" }}>
                                <SocialIcon bgColor = "white" 
                                            fgColor= "#5865F2"
                                            style ={{height: "100%", width: "100%", 
                                                position: "relative", zIndex: "1"}}
                                            url = "https://discord.com/"></SocialIcon>
                            </Circle>
                            </motion.div>

                            <motion.div
                                    initial={{ x: '-100%', opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 2, delay: 1.5 }}
                                >
                            <Circle border = "3px solid #282c34" margin = "10px"
                            size={{ base: "50px", md: "60px", lg: "70px" }}>
                                <SocialIcon bgColor = "white" 
                                            fgColor= "#1DB954" 
                                            style ={{height: "100%", width: "100%", 
                                                position: "relative", zIndex: "1"}}
                                            url = "https://open.spotify.com/user/31bgq7rvyj62fw34tfqqfshjbhqe?si=91672300d26d4ce2"></SocialIcon>
                            </Circle>
                            </motion.div>
                        </Box>
                    </Box>
                </Box>
                    <Box as="footer" textAlign="center" p={4} w="100%" color="white" fontSize={{ base: "xs", md: "sm", lg: "md" }}
                    _before={{content: "''",
                        display: "block",
                        width: "70%",
                        margin: "0 auto",
                        borderBottom: "1px solid white",
                        marginBottom: "10px"
                    }}>
                        © Copyright 2024 Made by Eshilama Akalumhe. All rights reserved.
                    </Box>
            </Box>
        </Box>
        </>
    )
}

export default ContactForm;