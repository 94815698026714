import {Box, Heading, Avatar, Image, Text, Highlight, Button} from '@chakra-ui/react';
import React from 'react';
import { useState, useEffect, useRef } from 'react';

import face from "./images/eshi.jpeg"

import './styles/about.css';
import { motion } from 'framer-motion';

/**
 * Renders a typing text effect with changing colors.
 *
 * @param {object} props - The component props.
 * @param {array} props.words - An array of words to be typed.
 * @param {array} props.colors - An array of colors to be used for typing.
 * @return {JSX.Element} The rendered typing text component.
 */
function TypingText({words, colors}){
    const [wordIndex, setWordIndex] = useState(0);
    const [colorIndex, setColorIndex] = useState(0);

    const [subStrLen, setSubStrLen] = useState(0);
    const [reverse, setReverse] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            setSubStrLen((length) => length + (reverse ? -1 : 1))
        }, 200);
        return () => clearInterval(timer);
    },[reverse]);

    useEffect(() => {
        if(subStrLen === words[wordIndex].length){
            setReverse(true);
        }
        else if (subStrLen === 0 && reverse){
            setReverse(false);
            setWordIndex((index) => (index + 1) % words.length);
            setColorIndex((index) => (index + 1) % colors.length);
        }
    }, [subStrLen, words, wordIndex, reverse]);

    return(
        <Text
            color={colors[colorIndex]}
            fontSize={{ base: "3xl", sm: "3xl", md: "3xl", lg: "5xl" }}
            as = {"kbd"}
            fontFamily={"Pathway Gothic One"}
            textShadow={"1px 1px #b5b6b7"} >
            {words[wordIndex].substring(0, subStrLen)}
        </Text>
    )
}
/**
 * Renders the About component.
 *
 * @return {JSX.Element} The rendered About component.
 */
function About({contactRef}) {
    const handleContactClick = () => {
        contactRef.current.scrollIntoView({ behavior: "smooth" });  
    }
    return (
        <>
        <Box className="about-container" 
            h={"100vh"} 
            display={"flex"} 
            flexDirection={{ base: "column", md: "row" }}
            justifyContent={"space-evenly"} 
            alignItems={"center"}
            padding={{ base: "20px", md: "50px" }}>

            <Box margin={"auto"} 
                display={"flex"} 
                flexDirection={"column"} 
                justifyContent={"space-evenly"} 
                alignItems={{ base: "center", md: "flex-start" }} 
                textAlign={{ base: "center", md: "left" }}
                w={{ base: "100%", md: "60%" }}
                padding={{ base: "10px", md: "20px" }}
                >

                <Heading  size={{ base: "2xl", md: "3xl" }}
                        color = "white" 
                        fontFamily={"Pacifico, cursive"}
                        textShadow={"1px 1px #b5b6b7"}>About <span class = "coloured-text">Me</span></Heading>
                
                <TypingText
                    words={['My name is Eshilama F. Akalumhe', 'A Software Engineer', 'A Full Stack Web Developer']}
                    colors={[ 'white', '#b5b6b7', '#878c90']} />
                
                <Text color = "#b5b6b7" 
                    fontSize={{ base: "md", sm: "md", md: "lg", lg: "xl", xl: "3xl" }}
                    fontFamily={"Abel, sans-serif"}
                    margin={"auto"} >
                    {/* <Highlight 
                        query = {['technology', 'innovation', 'software development', 'robotics']}
                        styles={{ px: '1', py: '1', bg: '#b5b6b7', color: 'white', borderRadius: 'full' }}>
                        
                    </Highlight> */}
                    Welcome to my portfolio! 
                        I am an aspiring <span className='coloured_text'>Software and Mechatronics Engineer</span> currently pursuing a Bachelor of Science degree at the University of Calgary. 
                        My journey is fueled by a deep-seated passion for technology and innovation, underscored by a strong commitment to fostering diversity in STEM fields, particularly in empowering women of color.
                        I aim to leverage my technical expertise in software development and robotics to spearhead groundbreaking solutions.
                       
                </Text>
        

            </Box>

            <Box as={motion.div}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 2.5, delay: 2}} 
                margin={"auto"}>

            <Image
                
                borderRadius={'full'}
                boxSize={{ base: '300px', md: '400px', lg: '400px', xl: '500px' }}
                border={"4         px solid #410b0b"}
                src = {face}
                alt = "EshI A"
                boxShadow={"15px 15px 15px rgba(0, 0, 0, 0.5)"}
                position={"relative"}
                margin={"auto"}
                transition={"all 0.25s"}
                _hover={{boxShadow: "20px 20px 10px rgba(0, 0, 0, 0.5)"}}
            ></Image>
            </Box>
            {/* <Avatar className="about-avatar" size="3xl" src="https://media.licdn.com/dms/image/D5603AQHiDyRcYagBow/profile-displayphoto-shrink_800_800/0/1699392497241?e=1717632000&v=beta&t=ucRzeKdODWU3qU7f6JN_OLU8hea-DQCXaYdLoBI0x6g" /> */}
        </Box>
        <Box position={"fixed"} 
            bottom={"0"}
            width={"100%"}
            bg={"transparent"}
            textAlign={"right"}
            p={4}
            zIndex={1000}>

        <motion.div
                initial={{ x: '-100%', opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1 }}
            >
        <Button onClick={handleContactClick} mt = {4} 
                size={{base:"sm", md:"md", lg: "md"}} 
                color={"#fff"}
                border={"2.5px solid #f2e2d2"}
   
                _hover={{backgroundColor: "#0b4141", color: "#000000", boxShadow: "1px 1px #000"}}
                boxShadow={"2px 1.5px #f2e2d2"}
                alignSelf={{ base: "center", md: "right", lg: "right" }}  
                backgroundColor={"#410b0b"}>
                <Text fontFamily={'Pathway Gothic One, sans-serif'} 
                width={"100%"} 
                fontSize={{ base: "xl", md: "2xl", lg: "3xl" }} 
                // textShadow={{ base: "1px 1px #000", md: "2px 2px #000", lg: "2px 2px #000" }}
                >contact me</Text>
                </Button>

        </motion.div>
                
        </Box>
        </>
    )
}

export default About;